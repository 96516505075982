$navbar-height: 60px;

@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../vendor/font-awesome-sass/assets/stylesheets/font-awesome";


.page-header .btn {
  margin-top: 25px;
}

 .draggable-handle {
   cursor: move;
 }

.draggable-handle:hover {
  background-color: ghostwhite;
}

.button-group {
  margin-bottom: 2em;
}
.button-group .number {
  font-size: 1.4em;
  color: dimgrey;
}

.button-group .text {
  font-size: 1.2em;
}
.button-group .text::first-letter {
  text-transform: uppercase;
}

.question-number {
  font-size: 3em;
  color: #999988;
  text-align:right;
}

.question-text {
  font-size: 1.2em;
  font-style: italic;
}

.question-buttons {
  margin-top:1em;
}

.control-panel .thumbnail {
  border: 1px solid black;
  padding: 0.5em;
  color: black;
  text-decoration: none;
}

.control-panel .thumbnail:hover {
  color: forestgreen;
  border: 1px solid forestgreen;
}

.allele-list {
  margin-left: -40px;
  list-style-type: none;
}
